@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

/* .nav-bar-container {
  display: flex;
  align-items: space-between;
  flex-direction: flex-end;
  width: 100%;
  background-color: aqua;
} */

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.help-page {
  margin-top: 5px;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.app-container {
  display: flex;
  margin-top: 75px;
  margin-left: 8px;
}

.inline_text {
  font-size: 3rem;
  color: black;
}

.home-wrapper {
  width: 80vw;
}

.page-container {
  width: 70w;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }

  .page-container {
    width: 50vw;
  }
}
.breadcrumb-item {
  text-decoration: none;
  font-size: 13px;
}
a {
  text-decoration: none;
}
