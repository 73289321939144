.form-demo .card {
  min-width: 450px;
}
.form-demo .card form {
  margin-top: 2rem;
}
.form-demo .card .p-field {
  margin-bottom: 1.5rem;
}
.p-field {
  margin-bottom: 2rem;
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}
.customButton {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.campaign a {
  text-decoration: none;
  font-size: 13px;
}
.campaign a:link,
a:visited {
  color: #2196f3;
}
.campaign a:hover {
  color: #d32f2f;
}
