@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

/* .nav-bar-container {
  display: flex;
  align-items: space-between;
  flex-direction: flex-end;
  width: 100%;
  background-color: aqua;
} */

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.help-page {
  margin-top: 5px;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.app-container {
  display: flex;
  margin-top: 75px;
  margin-left: 8px;
}

.inline_text {
  font-size: 3rem;
  color: black;
}

.home-wrapper {
  width: 80vw;
}

.page-container {
  width: 70w;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }

  .page-container {
    width: 50vw;
  }
}
.breadcrumb-item {
  text-decoration: none;
  font-size: 13px;
}
a {
  text-decoration: none;
}

.dialog-default {
  background: #ffffff;
  color: #495057;
  padding: 0 !important;
  overflow: hidden;
}

.form-demo .card {
  min-width: 450px;
}
.form-demo .card form {
  margin-top: 2rem;
}
.form-demo .card .p-field {
  margin-bottom: 1.5rem;
}
.p-field {
  margin-bottom: 2rem;
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}
.customButton {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.campaign a {
  text-decoration: none;
  font-size: 13px;
}
.campaign a:link,
a:visited {
  color: #2196f3;
}
.campaign a:hover {
  color: #d32f2f;
}

.help-page img {
  width: 750px;
}

.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datatable-crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-field {
  margin-bottom: 1.5rem !important;
}

.datatable-crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datatable-crud-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.treetable-editing-demo .p-treetable .p-treetable-tbody > tr > td.p-cell-editing {
    padding-top: 0;
    padding-bottom: 0;
}
.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
}
.background::after {
  content: "";
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media (min-width: 900px) {
  .background {
    padding: 0 0 25px;
  }
}

.container {
  margin: 0 auto;
  padding: 05px 0 0;
  max-width: 960px;
  width: 100%;
}
.container-bot {
  margin: 0 auto;
  padding: 05px 0 0;
  width: 100%;
}

.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.15), 0px 20px 31px 3px rgba(0, 0, 0, 0.29), 0px 8px 20px 7px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
.business-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.15), 0px 20px 31px 3px rgba(0, 0, 0, 0.29), 0px 8px 20px 7px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  flex-direction: row;
}
.h5-bot {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.15), 0px 20px 31px 3px rgba(0, 0, 0, 0.29), 0px 8px 20px 7px rgba(0, 0, 0, 0.12);
  display: flex;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  color: rgb(95, 93, 93);
  font-weight: 600;
  letter-spacing: 1px;
}
.dropdown {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-plan {
  border-bottom: 2px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 2px solid #e1f1ff;
    flex-basis: 100%;
    padding: 25px 50px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.pricing-features {
  color: #016ff9;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 50px 0 25px;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
  min-height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
  color: #016ff9;
  display: block;
  font-size: 25px;
  font-weight: 700;
  text-transform: none;
}

.pricing-button {
  border: 1px solid #9dd1ff;
  border-radius: 10px;
  color: #348efe;
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-featured {
  background-color: #48aaff;
  color: #fff;
}
.pricing-button.is-adquired {
  background-color: #17b280;
  color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  background-color: #269aff;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  left: -35px;
  content: "\2713";
}

/* Add a red text color and an "x" icon when the requirements are wrong */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left: -35px;
  content: "\2715";
}

